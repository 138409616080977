th,
td {
  background-color: #fff !important;
}

.sticky {
  top: 94px !important;
  position: sticky;
}
.ril-image-current.ril__image {
  object-fit: contain !important;
  height: 80vh;
  width: 1024px !important;
  transform: initial !important;
  object-position: center !important;
}

.ril__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}


.submit__btn.footer {
  z-index: 100 !important;
}