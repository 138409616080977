.specification {
    background-color: #fff;
    padding: 30px;
}


.save__btn {
    display: flex;
    justify-content: right;
    align-items: center;
    padding-top: 10px;
    margin-bottom: 30px;
    &.top {
        margin-bottom: 10px;
        padding-top: 0px;
    }
    button {
        background-color: var(--main-color) !important;     
        color: var(--text-color) !important;
        border: none;
        font-size: 16px;
        font-weight: 400;
        width: 204px;
        height: 45px;
        text-transform: capitalize;
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 10px;
    }
}


.overlay_coup {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    opacity: 0;
    transition: all 0.2s linear;
    &.active {
        display: flex;
        z-index: 5000;
        opacity: 1;
    }
    form {
        background-color: #fff;
        display: block;
        width: 400px;
        position: relative;
        z-index: 2;
        overflow-y: hidden;
        
        .head_form {
            position: absolute;
            top: 0;
            left: 0;
            height: 60px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            // box-shadow: 0 0 5px #ccc;
            border-bottom: 1px solid #dcdcdc;
            font-size: 16px;
            font-weight: 600;
            color: #000;
            z-index: 999;
            background-color: #fff;
            .log {
                display: flex;
                justify-content: left;
                gap: 3px;
                align-items: center;
            }
            button {
                border: none;
                background-color: transparent;
                font-size: 24px;
                i {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .inputs__wrap {
            height: 100%;
            width: 100%;
            padding: 70px 20px;
            padding-bottom: 20px;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .foot_form {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 75px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
            border-top: 1px solid #dcdcdc;
            font-size: 16px;
            font-weight: 600;
            color: #000;
            gap: 20px;
            z-index: 999;
            background-color: #fff;
            button {
                border: none;
                background-color: transparent;
                font-size: 16px;
                font-weight: 500;
                width: 50%;
                height: 40px;
                display: block;
                &.cancel {
                    border: 1px solid #000;
                }
                &.save {
                    background-color: #4C8866;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}

.react-tag-input {
    padding: 0;
    border-radius: 0;
    padding-left: 10px;
}  

.react-tag-input__input {
    height: 45px;
    &::placeholder {
        color: #DADADA !important;
        font-size: 14px;
    }
}

.react-tag-input__tag__content {
    font-size: 16px;
}


.image__options {
    display: flex;
    justify-content: center;
    width: fit-content;
    align-items: center;
    gap: 10px;
    float: right;
    button {
        border: none;
        background-color: transparent;
    }
}
body[data-sidebar="dark"] .mm-active .active {
    background-color: #384057 !important;
    // border-left: 5px solid #fff;
    &::after {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #fff;
    }
}


.metismenu {
    margin-top: 8px !important;
}

.input_cus {
    height: 45px;
    border-radius: 0 !important;
}