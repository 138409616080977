.head__title {
  display: flex;
  align-items: center;
  font-weight: 700 !important;
  font-size: 24px;
  i {
    font-size: 28px !important;
    margin-top: 7px;
    margin-right: 10px;
  }
}

h2 {
  color: #000 !important;
  font-weight: 700 !important;
}
input[type="text"]:not(.multiSelectContainer input),
input[type="number"],
input[type="date"],
select {
  border-radius: 0px !important;
  // height: 45px !important;
  border: 1px solid #dadada ;
  color: #000;
  text-transform: none;
  font-size: 14px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
  width: 100%;
  &.color__name {
    border-left: 0px !important;
    text-transform: lowercase !important;
  }
  &.is-invalid {
    border-color: #f46a6a !important;
  }
}
.is-invalid{
  border-color: #f46a6a !important;
}

input[type="color"] {
  height: 45px;
  border-right: 0px;
  border-radius: 0px;
  width: 10%;
}
input:disabled {
  background-color: #F1F1F1 !important;
}
button[disabled], html input[disabled]{
  background-color:#F1F1F1 !important;
}
input[type="text"][disabled]{
  background-color:#F1F1F1 !important;
}
.rs-picker-toggle{
  background-color:#F1F1F1 !important;

}





input[type="text"],
input[type="number"],
input[type="date"],
textarea {
  &::placeholder {
    color: #b6b6b6!important;
    font-size: 12px;
  }
}
select {
  cursor: pointer;
}
textarea {
  border-radius: 0px !important;
  border: 1px solid #dadada;
  color: #000;
  font-size: 14px !important;
  font-weight: 500 !important;
}

label {
  color: #000;
  text-transform: capitalize;
  font-weight: 500 !important;
  font-size: 14px;
  position: relative;
  margin-bottom: 5px !important;
  &.indec {
    color: #afafaf !important;
  }
  &.req {
    &::after {
      content: "*";
      position: absolute;
      color: #ff3a3a;
      font-size: 19px;
      left: 100%;
      top: -6px;
    }
  }
}

form {
  border-bottom: 1px solid #ececec !important;
  padding-bottom: 40px;
  display: block;
}

.submit__btn {
  position: fixed !important;
  z-index: 1 !important;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  &.footer {
    background-color: #fff !important;
    box-shadow: 0 2px 10px rgba($color: #12263f08, $alpha: 0.2);
    height: 70px;
    margin: 0 !important;
    padding: 0;
  }
  button {
    width: 150px;
    height: 40px;
    margin-bottom: 2px;
    margin-left: 5px;
    background: none;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    cursor: pointer;
    &.cancel {
      border: 1px solid #aeaeae;
    }
    &.save {
      border: 1px solid var(#3AAA35);
      background: #3AAA35;
      color: #fff;
    }
  }
}

.cancel {
  border: 1px solid #aeaeae;
  width: 150px;
  height: 40px;
  margin-bottom: 2px;
  margin-left: 5px;
  background: none;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}


.save {
  border: 1px solid var(#3AAA35);
  width: 250px;
  height: 40px;
  margin-bottom: 2px;
  margin-left: 15px;
  background: none;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
  background: #3AAA35;
  color: #fff;
}

.react-tag-input {
  padding: 0;
  border-radius: 0;
  padding-left: 10px;
}

.react-tag-input__input {
  height: 45px;
  &::placeholder {
    color: #dadada !important;
    font-size: 14px;
  }
}

.react-tag-input__tag__content {
  font-size: 16px;
}

.image__options {
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  gap: 10px;
  float: right;
  button {
    border: none;
    background-color: transparent;
  }
}

#react-select-2-input {
  height: 30px !important;
  border: none !important;
}

.select2-selection__control {
  border-radius: 0 !important;
  height: 44px;
  box-shadow: none;
}
.spasificBox:not(:first-of-type) {
  // border-top: 1px solid #ececec;
  // margin-top: 10px;
  // padding-top: 20px;
}


.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn {
  padding-right: 44px !important;
  border-radius: 0 !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn { 
    height: 48px !important;
    padding-top: 13px !important;

}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 13px !important; 
}
.rs-picker-default .rs-picker-toggle.rs-btn {
  border-radius: 0 !important;
  box-shadow: none;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #000 !important;
}
.rs-tree-node-disabled{
  color: #6a6b6c !important;
}

.css-g1d714-ValueContainer  {
  height: 100% !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  height: 45px !important;
  padding-top: 9px !important;
}

.submit__btn {
  z-index: 9999 !important;
}