.navs_aside {
    background-color: #fff;
    display: flex !important;
}

.nav___item {
    width:100% ;
    .tab_link {
        text-align: left;
        justify-content: left;
    }
}

.tab_link {
    border-radius: 0 !important;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 0px;
    color: #000 !important;
    height: 45px;
    width: 100%;
    text-align: left;
    display: flex !important;
    align-items: center;
    padding-left: 28px !important;
    &:hover {
        color: #000 !important;
    }
    a {
        padding: 0;
    }
    &.active {
        background-color: var(--main-color) !important;     
        color: var(--text-color) !important;
    }
}

.sticky {
    z-index: 100;
}

.css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 0 !important;
    height: 45px;
    box-shadow: none !important;
}

.css-1uccc91-singleValue{
    font-size: 16px !important;
}
// textarea input{
//     text-transform: none!important;
// }

.dragable_btn {
    position: absolute;
    top: 0;
    left: 0;
    height: 51.5px;
    border-radius: calc(0.25rem - 1px)  0 0 calc(0.25rem - 1px) ;
    background-color: transparent;
    transition: all 0.3s linear;
    z-index: 9;
    padding-bottom: 8px;
    border: none;
    outline: none;
    &:hover {
        background-color: #e0e0e0;
    }
}

.delete_btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 51.5px;
    border-radius: calc(0.25rem - 1px)  0 0 calc(0.25rem - 1px) ;
    background-color: transparent;
    transition: all 0.3s linear;
    z-index: 9;
    padding-right: 8px;
    padding-bottom: 8px;
    border: none;
    outline: none;
}
.toggle_button_drag {
    position: absolute;
    top: 0;
    left: 30px;
    height: 51.5px;
    border-radius: calc(0.25rem - 1px)  0 0 calc(0.25rem - 1px) ;
    background-color: transparent;
    transition: all 0.3s linear;
    z-index: 9;
    padding-right: 8px;
    padding-bottom: 8px;
    border: none;
    outline: none;
}