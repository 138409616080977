.css-1s2u09g-control {
    height: auto !important;
}
.css-1pahdxg-control {
    height: auto !important;
}


.nav___item {
    border-bottom: 1px solid #e8e8e8;
}