.specification {
  background-color: #fff;
  padding: 30px;
}

.save__btn {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 30px;
  &.top {
    margin-bottom: 10px;
    padding-top: 0px;
  }
  button {
    color: #fff;
    background-color: #3aaa35 !important;
    border: none;
    font-size: 16px;
    font-weight: 400;
    width: 150px;
    height: 45px;
    text-transform: capitalize;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
  }
}
.delete__btn {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 30px;
  &.top {
    margin-bottom: 10px;
    padding-top: 0px;
  }
  button {
    color: #fff;
    background-color: #d9534f !important;
    border: none;
    font-size: 16px;
    font-weight: 400;
    width: 180px;
    height: 45px;
    text-transform: capitalize;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
  }
}
.add__btn {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-top: 10px;
  margin-bottom: 30px;
  &.top {
    margin-bottom: 10px;
    padding-top: 0px;
  }
  button {
    background-color: var(--main-color) !important;
    color: var(--text-color) !important;
    border: none;
    font-size: 16px;
    font-weight: 400;
    width: 150px;
    height: 40px;
    text-transform: capitalize;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 10px;
  }
}

.overlay_coup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: -99;
  opacity: 0;
  transition: all 0.2s linear;
  &.active {
    z-index: 999999;
    opacity: 1;
  }
  form {
    background-color: #fff;
    display: block;
    width: 500px !important;
    position: relative;
    z-index: 2;
    //padding: 70px 20px;
    overflow-y: hidden;

    .head_form {
      position: absolute;
      top: 0;
      left: 0;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      // box-shadow: 0 0 5px #ccc;
      border-bottom: 1px solid #dcdcdc;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      z-index: 999;
      background-color: #fff;
      .log {
        display: flex;
        justify-content: left;
        gap: 20px;
        align-items: center;
      }
      button {
        border: none;
        background-color: transparent;
        font-size: 24px;
        i {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .inputs__wrap {
      height: 100%;
      width: 100%;
      padding: 70px 20px;
      padding-bottom: 20px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .foot_form {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 75px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-top: 1px solid #dcdcdc;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      gap: 20px;
      z-index: 999;
      background-color: #fff;
      button {
        border: none;
        background-color: transparent;
        font-size: 16px;
        font-weight: 500;
        width: 50%;
        height: 40px;
        display: block;
        &.cancel {
          border: 1px solid #000;
        }
        &.save {
          background-color: #4c8866 !important;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.table-responsive {
  &::-webkit-scrollbar {
    display: none;
  }
}

table {
  font-size: 15px;
  color: #000;
}

tbody {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

input[type="password"],
input[type="email"] {
  height: 46px;
  border-radius: 0;
}

.radio {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  input {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
  }
}

.usernametabl {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 0rem !important;
  margin-right: 10px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}


.submit__btn {
  z-index: 9999 !important;
}
